// MyComponent.scss

@import "../../styles//css/variables";

// .my-component {
//   //   color: $primary-color;
//   font-size: $font-size;

//   @include respond-to("sm") {
//     font-size: 14px;
//   }

//   @include respond-to("md") {
//     font-size: 16px;
//   }

//   @include respond-to("lg") {
//     font-size: 18px;
//   }

//   @include respond-to("xl") {
//     font-size: 20px;
//   }

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: var(--z-fixed);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
}

.nav__logo {
  color: var(--first-color);
  transition: color 0.4s;
  font-size: var(--h2-font-size);
  font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}

.nav__list__web {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
  align-items: center;
  list-style-type: none;
  padding: 0;
}
// }
.main-container {
  padding: 24px 40px;
  margin-top: 69px;
}
.main-container-mobile {
  padding: 10px 15px;
  margin-top: 69px;
}
