@import "../../styles//css/variables";

.tickets-filter {
  height: 36px;
  //   background-color: aliceblue;
  padding: 13px 16px;
  border: 1px solid #e5e7ec;
  border-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
  .filter-text {
    color: #637381;
    font-family: "segoe_uisemibold";
    font-size: $font-size-14;
    line-height: 22px;
    text-align: left;
  }
}
.green-color {
  color: green !important;
  font-family: "Segoe UI Bold" !important;
}

//// Ticket Form
.schedule-appointment-heading {
  font-family: "segoe_uisemibold";
  font-size: $font-size-18;
  font-weight: 600;

  color: $primary-bg-color;
}
.form-instruction {
  font-size: $font-size-14;
  opacity: 80%;
  color: $primary-bg-color;
}
.flexible-heading {
  color: $primary-bg-color;
  font-family: "segoe_uisemibold";
  font-size: $font-size-13;
}
.toggle-btn {
  // margin-left: 22px;
  .clr-white {
    color: #ffffff;
    font-size: 12px;
    font-family: "Segoe UI Bold";
    text-transform: uppercase;
  }
}
.dropdown-options {
  // font-family: Segoe UI;
  font-family: "Segoe UI Regular";
  font-size: $font-size-14;
  color: $primary-bg-color;
}
.dropdown-menu-options:hover {
  .dropdown-options {
    color: $active-text-clor;
  }
}

//Check box instruction

/* Hide the default checkbox */
.custom-checkbox input {
  display: none;
}

/* Create a custom checkbox */
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

/* Create the checkmark/indicator (hidden by default) */
.custom-checkbox .checkmark {
  width: 16px;
  height: 16px;
  // background-color: #ccc; /* Default background color */
  border-radius: 4px;
  position: relative;
  margin-right: 8px;
  border: 1px solid #919eab;
}

/* When the checkbox is checked, show the indicator */
.custom-checkbox input:checked + .checkmark {
  background-color: #00b8d9;
  border-color: #00b8d9;
}

/* Add the checkmark/indicator (hidden by default) */
.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked + .checkmark::after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark::after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.instruction-text {
  // font-family: Segoe UI;
  //"Segoe UI Regular"
  font-size: $font-size-14;
  line-height: 18.62px;
  text-align: left;

  color: #919eab;
}

// .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-virtualScrollerContent {
//   height: calc(96vh - 206px) !important;
//   overflow-y: auto;
// }
