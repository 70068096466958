@media (min-width: 764px) {
  .quill {
    //   background: white;
    //   color: black;
    //   border: 1px solid #ccc;
    height: calc(96vh - 300px);
  }
  .mt-76 {
    margin-top: 76px !important;
  }
}
@media (max-width: 764px) {
  .quill {
    //   background: white;
    //   color: black;
    //   border: 1px solid #ccc;
    height: calc(96vh - 348px);
  }
  .mt-76 {
    margin-top: 112px !important;
  }
}

.ql-toolbar.ql-snow {
  padding: 13px 8px;
  border: 1px solid #919eab40;
}

.ql-container.ql-snow {
  border: 1px solid #919eab40;
}
