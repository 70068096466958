.chat-heading-text {
  color: #16304f;
  font-size: 20px;
  font-family: "Segoe UI Bold";
}

.message {
  background: #fbfbff;
  border: 1px solid #e5e7ec;
  border-radius: 8px;
  .text {
    padding: 15px;
    color: #16304f;
    font-size: 15px;
  }
}
.message-date-time {
  color: #919eab;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
}
.canned-msg-div {
  background: #16304f29;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
  .text {
    padding: 4px 8px;
    color: #16304f;
    font-size: 15px;
  }
}
.canned-msg-list {
  background: #fbfbff;
  border: 1px solid #e5e7ec;
  border-radius: 8px;

  .canned-msg {
    width: 280px;
    padding: 12px 16px;
    cursor: pointer;
  }
}
.canned-menu {
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
}
