// checkbox

/* Hide the default checkbox */
.custom-checkbox-large input {
  display: none;
}

/* Create a custom checkbox */
.custom-checkbox-large {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

/* Create the checkmark/indicator (hidden by default) */
.custom-checkbox-large .checkmark-large {
  width: 20px;
  height: 20px;
  // background-color: #ccc; /* Default background color */
  border-radius: 4px;
  position: relative;
  border: 1px solid #919eab;
  margin-right: 0;
}

/* When the checkbox is checked, show the indicator */
.custom-checkbox-large input:checked + .checkmark-large {
  background-color: #00b8d9;
  border-color: #00b8d9;
}

/* Add the checkmark-large/indicator (hidden by default) */
.custom-checkbox-large .checkmark-large::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark-large when checked */
.custom-checkbox-large input:checked + .checkmark-large::after {
  display: block;
}

/* Style the checkmark-large/indicator */
.custom-checkbox-large .checkmark-large::after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.assign-jobs {
  color: #ed704799;
}
.assign-inspector-drop-down {
  .MuiInputLabel-root {
    // font-size: 15px;
    // font-family: "Segoe UI Regular";
    // text-align: left;
    // color: #16304f;
    // opacity: 40%;
    // border-radius: 4px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }
  .MuiOutlinedInput-input {
    padding: 6.5px 14px;
  }
}
.assign-inspector-table {
  .MuiDataGrid-footerContainer {
    display: none;
  }
}
