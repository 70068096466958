@import "../../styles//css/variables";

:root {
  // --main-bg-color: #3498db;
  // --main-text-color: #ffffff;
  --status-box-padding: 0px 12px;
}

.btn-forword-backword {
  padding: 10px 0px;
}
.calendar-header {
  border: 1px solid #d1d1d1;
  border-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
}
.month-name {
  font-size: $font-size-18 !important;
  font-family: "segoe_uisemibold" !important;
  text-align: center;
  color: $primary-bg-color;
  // margin-left: 20px;
}

@media (min-width: 500px) {
  .fc-col-header-cell {
    padding: 14px 16px !important;
    //   text-align: left;
  }
  .fc th {
    text-align: left;
  }
  .fc tbody td {
    height: 105px;
  }
}

.fc .fc-col-header-cell a {
  font-size: $font-size-14;
  font-family: "segoe_uisemibold";
  color: #969ba0;
  text-transform: uppercase;
}
// .fc-view-harness {
//   height: 600px !important;
// }
.fc-view-harness-active {
  height: 600px !important;
}
.fc .fc-daygrid-day-top {
  flex-direction: column-reverse;
}
.date-number {
  color: $primary-bg-color;
  font-size: $font-size-20;
  font-family: "Segoe UI Bold";
  margin: 7px;
  // padding-left: 14px !important;
  // padding-top: 11px !important;
  padding: 7px !important;
  font-size: 18px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fc-daygrid-day-events {
  display: flex;
  justify-content: end;
  margin-top: 18px !important;
}
@media (max-width: 1070px) {
  .fc-daygrid-day-events {
    display: flex;
    justify-content: end;
    margin-top: 10px !important;
  }
  // .fc-daygrid-day {
  //   height: 80px !important;
  // }
}
.fc-h-event {
  background-color: #ffffff;
  border: none;
}
.fc .fc-bg-event {
  opacity: 1;
  background-color: #f4f4f4;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #ffffff;
}
.fc-day-past {
  .date-number {
    // font-size: 18px;
    // width: 28px;
    // height: 28px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    color: #00000026;
    cursor: auto;
  }
}
.blocked-date {
  font-size: 16px;
  width: 28px;
  background: #16304f;
  color: #ffffff !important;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.status-detail-box {
  border: 1px solid var(--border-color, #e5e7ec);
  border-radius: 4px;
  padding: var(--status-box-padding);
  background-color: #ffffff;
  div {
    display: flex;
    align-items: center;
    .job-count {
      color: #355c9d;
      font-size: $font-size-16;
      font-family: "segoe_uisemibold";
    }
  }
}

.label-calendar-form {
  font-family: "segoe_uisemibold";
  font-size: 14px;
  line-height: 18.62px;
  text-align: left;
  color: #212b3699;
}
.calendar-form-value {
  font-family: "segoe_uisemibold";
  font-size: $font-size-16;
  line-height: 18.62px;
  text-align: left;
  color: #212b3699;
}

.popup-container {
  position: relative;
  overflow: visible;
}

.react-datepicker-popper {
  z-index: 9999;
}
.calendar-loader {
  // background-color: rgb(245, 245, 245);
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.text-field-calendar {
  padding: 0;
  .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
}
.fc-bg-event > div {
  position: absolute;
  bottom: 7px;
  right: 6px;
}
