// _variables.scss
$body-background-color: #f2f6fe;
$header-bg-color: #ffffff;
$header-border-color: #e5e7ec;
$avatar-bg-color: #dce6ec;
$sidebar-bg-color: #ffffff;

$active-text-clor: #ea3e23;
$primary-bg-color: #16304f;
$button-blue-color: #355c9d;
$button-orange-color: #ed7047;
$bg-green-color: #11b50c;
$bg-red-color: #e62b2a;
$bg-light-yello-color: #eeeb1e;
$bg-grey-color: #808080;

//text color
$primary-text-color: #16304f;

//font size
$font-size-base: 16px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;

//font weight
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

$color-white: #ffffff;
$color-black: #000000;
$white-button-bg: #ffffff;
$white-button-color: #212b36;
$white-button-border-color: rgba(145, 158, 171, 0.2);
$blue-text-color: rgba(27, 62, 97, 1);
$theme-color: rgba(27, 62, 97, 1);

// Values
$button-border-radius: 5px;

// Media queries breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// Dynamic variables
$font-size: $font-size-base;

@mixin respond-to($media) {
  @if $media == "xs" {
    @media (min-width: $breakpoint-xs) {
      @content;
    }
  } @else if $media == "sm" {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $media == "md" {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $media == "lg" {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if $media == "xl" {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  }
}
