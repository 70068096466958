.compliance-year-txt {
  color: #16304f;
  font-size: 16px;
  font-family: "segoe_uisemibold";
}
.mailer-sent-btn {
  border: 1px solid #16304f !important;
  color: #16304f !important;
  font-size: 14px !important;
  font-family: "segoe_uisemibold" !important;
  text-transform: capitalize !important;
  padding: 5px 29px !important;
}
.section-header-lead {
}
.input-select-box {
  .MuiInputBase-input {
    min-width: 142px;
  }
}
.survey-id-text {
  color: #637381;
  font-size: 16px;
}
.survey-form-container {
  .label-field {
    color: #16304f;
    font-size: 13px;
    font-family: "segoe_uisemibold";
    margin-right: 8px;
  }
  .verticle-div {
    margin-right: 8px;
  }
  .horizontal-div {
    .check-parent {
    }
    .check-child {
      margin-left: 30px;
    }
    .eng-language {
      color: #16304f;
      font-size: 16px;
      font-family: "Segoe UI Bold";
    }
    .eng-language-600 {
      color: #16304f;
      font-size: 16px;
      font-family: "segoe_uisemibold";
    }
    .eng-language-blue {
      color: #1883d2;

      font-size: 16px;
      font-family: "Segoe UI Bold";
    }
    .eng-language-blue-400 {
      color: #1883d2;

      font-size: 16px;
    }
    .span-language {
      color: #919eab;
      font-size: 14px;
    }
  }
}

.survey-count {
  margin-left: 4px;
  color: #637381;
  font-size: 14px;
  font-family: "Segoe UI Regular";
}
.survey-count-select {
  margin-left: 4px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Segoe UI Regular";
}
.client-table-page {
  .MuiDataGrid-overlayWrapperInner {
    height: calc(96vh - 302px) !important;
  }
}
.new-year-blank-compliance {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(98vh - 218px);

  border: 1px solid #e5e7ec;
  // border-bottom: 0px;
  border-radius: 8px 8px 8px 8px;
}
.overflow-auto {
  .MuiMenu-paper {
    overflow: auto;
  }
}
