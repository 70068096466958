

.sigCanvas{
    border: 1px solid  #E5E7EC;
    width: 100%;
    height:136px;
}
.scan-container{

}

.lead-paint-comp-header {
    position: relative;
    background: #16304F;
    padding: 15px 16px;
    color: #fff;
        width: 100%;
}
.scan-container {
    max-width: 765px;
    margin: 0 auto;
    border: 1px solid #eee;
    position: relative;
}
.lead-paint-comp-header:before{
    content: "";
    position: absolute;
    background:url('../../styles/svg/bottom-shape.svg');
    width: 100%;
    background-size: 100% 100%;
    background-position: center;
    height: 30px;
    bottom: -20px;
    left: 0;
}
.lead-paint-comp-header p {
    text-align: center;
    margin: 0;
    top: 4px;
    position: relative;
}
// .lead-paint-comp-header:before {
//     content: "";
//     position: absolute;
//     bottom: -4px;
//     width: calc(50% + 1px);
//     height: 10px;
//     background: #16304F;
//     transform: rotate(3deg);
//     left: 0;
// }
// .lead-paint-comp-header:after {
//     content: "";
//     position: absolute;
//     bottom: -4px;
//     width: calc(50% + 1px);
//     height: 10px;
//     background: #16304F;
//     transform: rotate(-3deg);
//     right: 0;
// }

.lead-compliance-scan-form{
    .popup-footer-border {
     left: 0px;
    width: 100%;
    padding: 20px 0px;
}
}
@media (max-width: 520px) {
  .lead-paint-comp-header{
    font-size: 18px;
    line-height: 1.2;
  }
}
