.table-actions {
  .filter-select .MuiOutlinedInput-input {
    font-size: 13px;
    font-family: "Segoe UI Regular";
    color: #16304f;
    opacity: 1;
  }

  .MuiMenuItem-root {
    // .text-status {
    font-size: 14px;
    font-family: "Segoe UI Regular";
    opacity: 80%;
    color: #16304f;
    // }
  }
  .filter-select {
  }
}
// .MuiMenu-paper {
//   height: auto !important;
// }
.text-status {
  font-size: 13px;
  font-family: "segoe_uisemibold";
  color: #16304f;
  // opacity: 80%;
}
.client-table-page {
  .MuiDataGrid-overlayWrapperInner {
    height: calc(96vh - 302px) !important;
  }
}

.client-detail {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #e5e7ec;
}
.building-info {
  color: #919eab;

  font-size: 14px;
  margin-top: 8px;
}
.exempt-field {
  margin-top: 10px;
}
.isexempt-checkbox {
  margin-top: 12px;
}

///Details
///
.p-24 {
  padding: 24px;
}
.client-detail-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 142px;
}
.detail-container {
  border-radius: 12px;
  margin-bottom: 30px;
  box-shadow: 0px 12px 23px 0px #3e49540a;
  border: 1px solid #e5e7ec;
  position: relative;
}
@media (min-width: 700px) {
  .box-container {
    justify-content: space-around;
    display: flex;
  }
}
@media (max-width: 699px) {
  .box-container {
    // justify-content: space-around;
    // display: flex;
  }
}
.heading-title-name {
  color: #16304f;
  font-size: 18px;
  font-family: "segoe_uisemibold";
}
.title-details {
  color: #637381;
  font-size: 15px;
}
.detail-action {
  position: absolute;
  right: 0;
  padding: 12px;
}
.table-bottom {
  height: 27px;
  padding: 13px 16px;
  border: 1px solid #e5e7ec;
  border-top: 0px;
  border-radius: 0px 0px 8px 8px;
  margin-top: -52px;
}
.bottom-detail {
  color: #16304f;
  font-family: "segoe_uisemibold";
  font-size: 16px;
}
.unit-ticket {
  border-radius: 4px;
  padding: 6px 12px;
  background: #0e56d1;
  color: #ffffff;
  font-size: 14px;
  font-family: "segoe_uisemibold";
}
.view-all {
  color: #ed7047;
  font-size: 14px;
  font-family: "Segoe UI Bold";
  margin-right: 11px;
  text-decoration: underline;
}

.unit-service-icons {
  color: #16304f;
  font-size: 13px;
  font-family: "segoe_uisemibold";
}
.mailer-txt-unit {
  color: #16304f;
  font-size: 15px;
  font-family: "segoe_uisemibold";
}
