@import "../../styles//css/variables";

.radio-input {
  width: 20px;
  height: 20px;
  color: #919eab;
}
.title-radio {
  //   font-family: "segoe_uisemibold";
  font-size: $font-size-14;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #16304f99;
}

/////
///
///
///
/* Hide the default radio button */
.custom-radio input[type="radio"] {
  display: none;
}

/* Style the custom radio button */
.custom-radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  font-size: 16px;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #919eab;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-radio input[type="radio"]:checked + .radio-checkmark {
  background-color: #ffffff;
  border-color: #00b8d9;
}

.custom-radio input[type="radio"]:checked + .radio-checkmark::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #00b8d9;
  transform: translate(-50%, -50%);
}
