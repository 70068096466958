@import "variables";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

* {
  margin: 0;
  padding: 0;
}

// 500-600
@font-face {
  font-family: "segoe_uisemibold";
  src: url("segoe-ui-semibold-webfont.woff2") format("woff2"),
    url("segoe-ui-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

//400
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"), url("Segoe UI.woff") format("woff");
}

//700
@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"), url("Segoe UI Bold.woff") format("woff");
}

body {
  font-family: "Segoe UI Regular";
  color: black;

  .react-date-range-popup {
    .rdrInputRanges {
      display: none;
    }
  }
  .MuiInputLabel-root {
    font-size: 15px;
    font-family: "Segoe UI Regular";
    text-align: left;
    color: #16304f;
    opacity: 40%;
    border-radius: 8px;
  }
  .MuiInputBase-input {
    font-family: "Segoe UI Regular";
    font-size: 15px;
    color: #16304f;
    opacity: 0.8;
  }
  .MuiOutlinedInput-root {
    // border: 1px solid #e5e7ec;
  }
  .MuiInputBase-formControl:focus {
    border: 0;
    outline: none;
  }
  .MuiOutlinedInput-notchedOutline {
    font-size: $font-size-14;
    font-family: "Segoe UI Regular";
    text-align: left;
    color: #16304f;
    // opacity: 40%;
    border-color: #e5e7ec;
    border-radius: 8px;
  }
  .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-virtualScrollerContent {
    height: calc(96vh - 230px) !important;
    overflow-y: auto;
  }
  .placeholder-select {
    font-style: normal;
    font-size: 15px;
    font-family: "Segoe UI Regular";
    text-align: left;
    color: #16304f;
    opacity: 40%;
  }
}
// .MuiTypography-root-MuiDialogTitle-root {
//   line-height: 0%;
// }
.react-datepicker-wrapper {
  width: 100%;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}
.opacity-3 {
  opacity: 0.3;
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
.p-relative {
  position: relative;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-left {
  justify-content: left;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-end-important {
  justify-content: end !important;
}
.justify-content-space-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.d-none {
  display: none;
}
.capitalize {
  text-transform: capitalize !important;
}
.w-100 {
  width: 100%;
}
.pl-0 {
  padding-left: 0 !important;
}
.p-0-important{
  padding: 0 !important;
}
.pa-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.p-4 {
  padding: 0px 16px;
}
.p-24-0 {
  padding: 24px 0;
}
.mr-30 {
  margin-right: 30px;
}
.ml-1 {
  margin-left: 6px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 16px !important;
}
.ml-44 {
  margin-left: 44px;
}
.mr-1-1 {
  margin-right: 7px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-44 {
  margin-right: 44px;
}

.mr-20 {
  margin-right: 20px;
}
.mr-40 {
  margin-right: 40px;
}
.ml-30 {
  margin-left: 30px;
}
.mr-2px {
  margin-right: 2px;
}
.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mb-6 {
  margin-bottom: 36px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-6 {
  margin-top: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.h-36 {
  height: 36px;
}
.mt-2 {
  margin-top: 8px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-1{
  margin-bottom: 4px;
}
.w-25 {
  width: 110px;
}
.w-170px {
  width: 170px;
}
.w-200px {
  width: 200px;
}
.margin-right-1{
  margin-right: 4px !important;
}
.d-grid {
  display: grid;
}
.text-red{
  color: red;
}
.text-green{
  color: #54b62d;
}
.back-button {
  color: #16304f;

  font-size: 15px;
  font-style: italic;
  text-decoration: underline;
}
.icon-m-0 {
  .MuiButton-icon {
    margin: 0;
  }
}
.center-header {
  .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100% !important;
  }
}
.lead-compliance-scan-form{
  padding: 10px 14px;
}
.orange-color {
  color: #ed7047;
}
.font-family-700 {
  font-family: "Segoe UI Bold";
}
.required-star {
  color: red;
}
.green-clr {
  background-color: #11b50c !important;
}
.blue-clr {
  background: #355c9d;
}
.min-208 {
  min-width: 175px !important;
}
//buttons
.popup-header-button {
  padding: 0px 16px !important;
  margin-right: 35px !important;
}
.reset-date-picker,
.submit-date-picker {
  bottom: 18px;
  font-size: 16px !important;
  font-family: "segoe_uisemibold" !important;
  height: 20px !important;
  line-height: 20px;
  margin-left: 12px !important;
  padding: 14px !important;
  position: absolute !important;
  color: #ed7047 !important;
  width: 43px !important;
  text-transform: capitalize !important;
}
.h-42 {
  height: 42px !important;
  font-family: "segoe_uisemibold" !important;
  font-size: $font-size-16 !important;
}
.primary-btn-class {
  height: 42px !important;
  border-radius: 4px !important;
  background-color: #ed7047 !important;
  color: #ffffff !important;
  font-family: "segoe_uisemibold" !important;
  font-size: $font-size-16 !important;
  text-transform: capitalize !important;
}
.secondary-btn-class {
  height: 42px !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  color: #16304f !important;
  font-family: "segoe_uisemibold" !important;
  font-size: $font-size-16 !important;
  text-transform: capitalize !important;
  border: 1px solid #16304f;
  opacity: 40%;
}
.upload-btn {
  color: #16304f !important;
  font-family: "segoe_uisemibold" !important;
  font-size: $font-size-14 !important;
  line-height: 22px !important;
  text-align: center !important;
  text-transform: capitalize !important;
}

//End buttons

// Toggle button

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
  outline: none;
}

.switch input {
  // position: absolute;
  // top: -99999px;
  // left: -99999px;
  visibility: hidden;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1b3e613d;
  box-shadow: 0px 4px 16px 0px #1b3e610f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

/* .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
} */
.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  /* background-color: rgb(20, 37, 103); */
  background-color: #1b3e61;
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(20, 37, 103);
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}

.clr-white {
  color: #ffffff;
  font-size: 12px;
  font-family: "Segoe UI Bold";
  text-transform: uppercase;
}

// End Toggle

// Date Range
.rdrDayStartOfRange .rdrDayNumber span {
  background-color: #ffcc00 !important; /* Color for start date */
}
.rdrDayEndOfRange .rdrDayNumber span {
  background-color: #ff6600 !important; /* Color for end date */
}
// Date Range

.form-btn-bottom {
  border-bottom: 1px solid #e5e7ec;
  margin-bottom: -74px;
}
.underline-on-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}
.btn-bg-orange {
  background-color: $button-orange-color !important;
}
.btn-bg-light-grey {
  background-color: #f5f5f9 !important;
  color: #16304f !important;
  font-family: "segoe_uisemibold" !important;
  font-size: $font-size-14 !important;
  margin-right: 8px !important;
}
.btn-bg-grey {
  background-color: #808080 !important;
  color: #ffffff !important;
  font-family: "segoe_uisemibold" !important;
  font-size: $font-size-14 !important;
  margin-right: 8px !important;
}
.btn-bg-blue-primary {
  background-color: $primary-bg-color !important;
}
.bg-blue-status {
  background-color: $button-blue-color !important;
}
.bg-orangr-status {
  background-color: orange !important;
}
.bg-green-status {
  background-color: $bg-green-color;
}

.loader-center {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 60vh;
}

.page-heading-name {
  font-family: "Segoe UI Bold";
  font-size: $font-size-24;
  color: $primary-bg-color;
}
.page-heading-service {
  font-family: "Segoe UI Bold";
  font-size: 16px;
  color: $primary-bg-color;
}

.icon-defination {
  font-size: $font-size-13;
  font-family: "segoe_uisemibold";
  text-align: center;
  color: $primary-bg-color;
  // margin-left: 20px;
}
.status-chip {
  border-radius: 19px;
  font-size: 13px;
  height: 24px;
  color: #ffffff;
  padding: 0px 12px;
  display: flex;
  align-items: center;
}
.search-filter {
  .MuiInputBase-input {
    padding: 5px 8px 5px 0px !important;
    padding-left: calc(1em + 32px) !important;
  }
  input {
    font-size: 14px;
    font-family: "Segoe UI Regular";
  }
}
.dialog-title {
  font-family: "Segoe UI Bold" !important;
  font-size: $font-size-20 !important;
  color: #16304f;
}
// .select-box {
.MuiMenu-paper {
  // background-color: red !important;
  margin-top: 4px;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #e5e7ec;
  max-height: 220px !important;
}
// }
.filter-select {
  .MuiOutlinedInput-input {
    font-size: $font-size-13;
    font-family: "Segoe UI Regular";
    color: #16304f;
    opacity: 40%;
  }
}

.popup-footer-border {
  // border-top: 1px solid red;
  border-top: 1px solid #e5e7ec;
  display: block !important;
  width: calc(100% + 60px);
  text-align: right;
  left: 30px;
  position: relative;
  padding: 12px 30px;
  margin-top: 30px !important;
}
.dialog-content-form {
  padding: 32px 38px 0 !important;
}
@media (max-width: 475px) {
  .MuiDialog-root {
    border-radius: 0;
  }
  .MuiDialog-paper {
    border-radius: 0 !important;
  }
}
// @media (min-width: 600px) {
.MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important ;
}
// }

.custom-checkbox-large input:checked + .checkmark-large-radio::after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #00b8d9;
    display: block;
}
.custom-checkbox-large .checkmark-large-radio {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    position: relative;
    border: 1px solid #919eab;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

body .MuiInputLabel-root.MuiInputLabel-shrink {
  opacity: 1;
  color: #16304f;
  background: #fff;
  padding: 0px 6px;
}

.Mui-disabled {
  background: #f5f5f9;
  border-radius: 6px;
}

.table-Filters-padding{
  padding: 13px 16px;
}

.height-62{
  height: 62px !important;
}

.lead-compliance-unit-table{
  background-color: #ED704729;
  padding: 0 8px;
}
.lead-compliance-unit-table-gred{
  opacity: 0.4;
    pointer-events: none;
  padding: 0 8px;
}

.audit-button{
  background-color:  #16304F !important;
  opacity: 60%;
}

//table
.tickets-filter {
  height: 36px;
  //   background-color: aliceblue;
  padding: 13px 16px;
  border: 1px solid #e5e7ec;
  border-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
  .filter-text {
    color: #637381;
    font-family: "segoe_uisemibold";
    font-size: $font-size-14;
    line-height: 22px;
    text-align: left;
  }
}
.secondary-heading-text {
  font-family: "segoe_uisemibold";
  font-size: $font-size-18;

  color: #16304f;
}
.dropdown-filter {
  // .MuiOutlinedInput-input {
  //   padding: 6.5px 14px !important;
  //   border-radius: 4px;
  // }
}
.clear-filter {
  color: #355c9d;
  // font-family: Segoe UI;
  font-size: 14px;
  font-style: italic;
  font-family: "Segoe UI Regular";
  text-decoration: underline;
  margin-right: 20px;
  cursor: pointer;
}
.add_more_apt {
  font-size: 13px;
  font-weight: 600;
  margin-left: 5px;
}
.add_enter_rows {
  height: 42px !important;
  border-radius: 4px !important;
  background-color: #11b50c !important;
  color: #ffffff !important;
  font-family: "segoe_uisemibold" !important;
  font-size: $font-size-16 !important;
  text-transform: capitalize !important;
}
