@import "../../styles//css/variables";

.page-header-text {
  color: #16304f;
  font-size: $font-size-24;
  font-family: "Segoe UI Bold";
  margin-bottom: 16px;
}

.info-text {
  color: #16304f;
  font-size: $font-size-16 !important;
  font-family: "Segoe UI Regular" !important;
  margin-bottom: 35px !important;
}
.right-box {
  height: 100vh;
  position: relative;
}
.text-overlay {
  left: 0;
  bottom: 0;
  transform: inherit;
  text-align: left;
  padding: 30px;
  color: #ffffff;
}
.right-box:before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(360deg, #011324 0%, rgba(102, 102, 102, 0) 100%);
}
.sign-up-bottom-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.forgot-text {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0px 10px 0px;
}
.sign-up-bottom {
  color: #16304f;
  font-size: 14px;

  span {
    font-family: "segoe_uisemibold";
    text-decoration: underline;
    cursor: pointer;
  }
}
