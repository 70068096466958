@import "../../styles/css/variables";

body {
  @media (min-width: 500px) {
    .app-header {
      background: $header-bg-color;
      box-shadow: none;
      border-bottom: 1px solid $header-border-color;
      padding: 8px 24px;
      color: black;
    }
  }
  @media (max-width: 500px) {
    .app-header {
      background: $header-bg-color;
      box-shadow: none;
      border-bottom: 1px solid $header-border-color;
      padding: 8px 15px;
      color: black;
    }
  }

  // .nav-link {
  //   font-size: $font-size-16;
  //   font-weight: $font-weight-400;
  //   line-height: 21.28px;
  //   letter-spacing: 0.01em;
  //   color: #637381;
  //   text-decoration: none;
  // }
  // .user-menu-paper {
  //   .MuiMenu-paper {
  //     height: fit-content !important;
  //   }
  // }
  .nav__list__web-r {
    list-style: none;

    a {
      font-size: $font-size-16;
      font-weight: $font-weight-400;
      line-height: 21.28px;
      letter-spacing: 0.01em;
      color: #637381;
      text-decoration: none;
      &.active {
        // button{
        font-size: $font-size-16;
        font-weight: $font-weight-600;
        line-height: 21.28px;
        color: $active-text-clor;
        // }
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    justify-content: end;
    .base-MenuButton-root {
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  }
  #user-menu {
    .MuiPaper-root {
      min-width: 200px;
    }
  }
  .profile-header {
    .MuiMenu-paper {
      height: fit-content;
    }
    .MuiPopover-paper {
      -webkit-filter: drop-shadow(0px 4px 50px #1b3e6140);
      filter: drop-shadow(0px 4px 50px #1b3e6140);
      border-radius: 6px;
      border: 1px solid rgb(145 158 171 / 25%);
      ul {
        padding: 0;
        li {
          padding: 16px;
          border-bottom: 1px solid rgb(145 158 171 / 25%);
          font-size: 14px;
          font-weight: 500;
          &:last-child {
            border-bottom: 0;
          }
          .profile-dropdown-email {
            font-size: 12px;
            font-family: "segoe_uisemibold";
            color: #16304f;
            opacity: 60%;
          }
          .profile-dropdown-name {
            font-size: $font-size-14;
            font-family: "Segoe UI Bold";
            line-height: 1;
            margin-bottom: 6px;
            color: #16304f;
          }
        }
      }
    }
  }
}
.user-avatar {
  background-color: $primary-bg-color !important;
}
.user-avtar-menu {
  height: 36px;
  width: 36px;
  background-color: #16304f;
}
.font-user-short {
  // color: #ffffff;
  font-size: $font-size-14;
  font-family: "Segoe UI Bold";
}
.avatar-name {
  font-family: "Segoe UI Bold";
  color: #ffffff;
  font-size: $font-size-14;
  line-height: 18px;
  text-align: center;
}
.logout-text {
  color: #16304f;
  font-size: $font-size-14;
  font-family: "Segoe UI Regular";
}

// body {
//   .app-header {
//     background: $header-bg-color;
//     box-shadow: none;
//     border-bottom: 1px solid $header-border-color;
//     padding: 8px 24px;
//     .logo {
//       img {
//         max-width: 170px;
//         max-height: 30px;
//       }
//     }
//     .user-avatar {
//       width: 32px;
//       height: 32px;
//       // color: #1b3e61;
//       // background-color: $avatar-bg-color;
//       font-size: 14px;
//       border: 1px solid #c7ced4;
//     }
//     .profile-down-arrow {
//       font-size: 24px;
//       fill: rgb(145 158 171 / 50%);
//     }
//     .header-right {
//       display: flex;
//       align-items: center;
//       justify-content: end;
//       .base-MenuButton-root {
//         background: transparent;
//         border: 0;
//         cursor: pointer;
//       }
//     }
//   }

// }
