.service-paper {
  margin-top: 4px;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #e5e7ec;
  box-shadow: 0px 12px 23px 0px #3e49540a !important;

  .service-name {
    font-family: "segoe_uisemibold";
    font-size: 18px;
    color: #16304f;
    padding-right: 42px;
  }
}
.status-text {
  font-family: "segoe_uisemibold";
  font-size: 13px;
  color: #16304f;
}
.status-grid {
  margin-top: 30px !important;
}
.long-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 82px;
    height: 30px;
    outline: none;
  }

  .switch input {
    // position: absolute;
    // top: -99999px;
    // left: -99999px;
    visibility: hidden;
    opacity: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1b3e613d;
    box-shadow: 0px 4px 16px 0px #1b3e610f;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  /* .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
} */
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 5px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    /* background-color: rgb(20, 37, 103); */
    background-color: #1b3e61;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px rgb(20, 37, 103);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(52px);
    -ms-transform: translateX(52px);
    transform: translateX(52px);
  }

  .clr-white {
    color: #ffffff;
    font-size: 12px;
    font-family: "Segoe UI Bold";
    text-transform: capitalize;
  }
}
